import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";
const unitHwTypeTransformation = {
    xcargo: 'xgrafana',
    tive: 'xgrafana',
    geometris: 'obd',
    suntech: 'obd',
    pro_cargo_vision: 'pro_cargo_vision',
}
function getUnitDefaultRouteParams (unitId) {
    let unit = store.getters.unitsByIds[unitId]
    localStorage.setItem(store.getters.getUserStorageKey+'.unit.active', unitId)
    let template = unitHwTypeTransformation[unit?.hw_type] || unit?.hw_type
    let routeName = consts.routerNames?.units.type[template]

    //if(store.getters.isUnitsFullLoad){
        if(!routeName) {
            if(unit?.video) {
                routeName = consts.routerNames?.units?.type?.xvision
            } else
            if(unit?.reefer_type) {
                routeName = consts.routerNames?.units?.type?.xcool
            } else {
                routeName = consts.routerNames?.units?.type?.xtrack
            }
        }
    //}
    if(!unitId) routeName = consts.routerNames.units.main

    return {
        routeName,
        type: template,
        query: {
            unitId,
        }
    }
}

const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.portal') ? [] : [
    {
        path: '/units/:noList?',
        name: consts.routerNames.units.main,
        meta: {
            list: 'UnitsList',
            mainRouteName: consts.routerNames.units.main,
            showMapInfo: false,
            showMap: true,
            weatherInMap: true,
            unitTracking: true,
            isLimited: true,
            pageLayout: 'block-position-unit',
            setRoute: {
                units: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('units', route);
                        return false;
                    }
                    router.push({
                        name: consts.routerNames.units.main,
                        params: {
                            unitId,
                        },
                        query: {}
                    })
                },
                returnToParent:(route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn(consts.routerNames.units.main+' returnToParent', route);
                        return false;
                    }
                    let name = route.meta?.parentRouteName || consts.routerNames.units.main;
                    router.push({
                        name: name,
                        params: {
                            ...route.params,
                        },
                        query: {...route.query}
                    })
                },
                unitsAll: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('units', route);
                        return false;
                    }
                    router.push({
                        name: consts.routerNames.units.all,
                        params: {},
                        query: {...route.query}
                    })
                },
                unitsAllUnitInfo: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitInfo', route);
                        return false;
                    }
                    let routeName = consts.routerNames.units.all
                    router.push({
                        name: routeName + '.info',
                        params: {
                            unitId,
                        },
                        query: {...route.query}
                    })
                },
                unitId: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitId', route);
                        return false;
                    }
                    if(store.getters?.unitsOnMap?.length) {
                        store.commit('setUnitsShowOnMap', {
                            showOnMap: false,
                            unitIds: store.getters?.unitsOnMap
                        })
                    }
                    let routeParams = getUnitDefaultRouteParams(unitId)

                    router.push({
                        name: routeParams?.routeName,
                        params: {
                            ...routeParams?.query,
                        },
                        query: {...route.query}
                    })
                },
                liteTemplate: (route, router, lite, noList=false) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('liteTemplate', route);
                        return false;
                    }
                    let unitId = route.params.unitId
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    let routeName = routeParams?.routeName;
                    if(lite && !consts.templates.unitLite.extensions.byUnitHwType.includes(routeParams?.type)) {
                        routeName += '.lite'
                    }
                    router.push({
                        name: routeName,
                        params: {
                            ...routeParams?.query,
                            noList: noList ? 'no-list' : '',
                        },
                        query: {...route.query}
                    })
                },
                unitEdit: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitEdit', route);
                        return false;
                    }
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: routeParams?.routeName + '.edit',
                        params: {
                            ...routeParams?.query,
                        },
                        query: {...route.query}
                    })

                },
                unitInfo: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitInfo', route);
                        return false;
                    }
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: routeParams?.routeName + '.info',
                        params: {
                            ...routeParams?.query,
                        },
                        query: {...route.query}
                    })
                },
                unitReefer: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitReefer', route);
                        return false;
                    }
                    router.push({
                        name: consts.routerNames.units.type.xcool + '.reefer',
                        params: {
                            unitId,
                        },
                        query: {...route.query}
                    })
                },
                unitStationAnalytics: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitStationAnalytics', route);
                        return false;
                    }
                    router.push({
                        name: consts.routerNames.units.type.xlink + '.analytics',
                        params: {
                            unitId,
                        },
                        query: {...route.query}
                    })
                },
                unitGenerator: (route, router, unitId, name) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitGenerator', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.units.type.xpower + '.' + name,
                        params: {
                            unitId,
                        },
                        query: {...route.query}
                    })
                },
                unitsFilter: (route, router, filter) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitsFilter', route);
                        return false
                    }
                    let unitId = route.params?.unitId
                    let eventXid = route.params?.eventXid
                    router.push({
                        name: route.name,
                        params: {
                            unitId,
                            eventXid,
                        },
                        query: {...filter}
                    })
                },
                liveVideo: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('liveVideo', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.units.type.xvision + '.live-video',
                        params: {
                            unitId,
                        },
                        query: {...route.query}
                    })
                },
                unitSnapshots: (route, router, unitId, eventXid) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames?.units?.main) {
                        if(this.$isDevelopment) console.warn('unitId', route);
                        return false;
                    }
                    let isLite = route.name.split('.').includes('lite')
                    let noList = route.params.noList
                    router.push({
                        name: consts.routerNames.units.type.xvision + '.snapshots' + (isLite ? '.lite' : ''),
                        params: {
                            unitId,
                            eventXid,
                            noList
                        },
                        query: {...route.query}
                    })
                },
                'video-event': (route, router, unitId, eventXid) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('video-event', route);
                        return false
                    }
                    let isLite = route.name.split('.').includes('lite')
                    let noList = route.params.noList
                    router.push({
                        name: consts.routerNames.units.type.xvision + '.video-event' + (isLite ? '.lite' : ''),
                        params: {
                            unitId,
                            eventXid,
                            noList
                        },
                        query: {...route.query}
                    })
                },
                'video-request': (route, router, unitId, eventXid) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('video-request', route);
                        return false
                    }
                    let isLite = route.name.split('.').includes('lite')
                    let noList = route.params.noList
                    router.push({
                        name: consts.routerNames.units.type.xvision + '.video-request' + (isLite ? '.lite' : ''),
                        params: {
                            unitId,
                            eventXid,
                            noList
                        },
                        query: {...route.query}
                    })
                },
                fastGeoitem: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('fastGeoitem', route);
                        return false
                    }
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    router.push({
                        name: routeParams?.routeName + '.fastGeozone',
                        params: {
                            ...routeParams.query,
                        },
                        query: {...route.query}
                    })
                },
                track: (route, router, unitId, date = store.getters.getDateToday) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('track', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.tracks.main,
                        params: {
                            unitId,
                            date,
                        },
                        query: {}
                    })
                },
                unitTrackXid: (route, router, date, type, trackXid, timeFrom, timeTo, trackColor, filter) => {
                    if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('trackXid', route);
                        return false
                    }
                    let unitId = route?.params?.unitId
                    let routeParams = getUnitDefaultRouteParams(unitId)
                    trackColor = trackColor ? 'track-color' : ''
                    router.push({
                        name: route.name,
                        params: {
                            ...routeParams.query,
                            date,
                            type,
                            trackXid,
                            timeFrom,
                            timeTo,
                            trackColor,
                        },
                        query: {
                            ...route.query,
                            ...filter,
                        }
                    })
                },
                unitsGroupEdit: (route, router, groupId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitsGroupEdit', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.units.edit.group,
                        params: {
                            groupId,
                        },
                        query: {...route.query}
                    })

                },
                unitCommand: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitCommand', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.units.commands.main,
                        params: {
                            unitId,
                        },
                        query: {...route.query}
                    })

                },
                unitsGroupCommand: (route, router, groupId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitsGroupCommand', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.units.commands.group,
                        params: {
                            groupId,
                        },
                        query: {...route.query}
                    })

                },
                unitsLocator: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitsLocator', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.units.locator,
                        params: {
                            unitId,
                        },
                        query: {...route.query}
                    })

                },
                unitChat: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitChat', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.units.chat,
                        params: {
                            unitId,
                        },
                        query: {...route.query}
                    })

                },
                unitAOVXSwitch: (route, router, unitId, routeName) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('shipmentId', route);
                        return false;
                    }
                    router.push({
                        name: routeName,
                        params: {
                            ...route.params,
                            unitId,
                        },
                        query: {...route.query}
                    })
                },
                unitChangePrams: (route, router, params={}, query={}) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitChangePrams', route);
                        return false;
                    }
                    router.push({
                        name: route.name,
                        params: {
                            ...route.params,
                            ...params,
                        },
                        query: {...route.query, ...query}
                    })
                },
                obdMap: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
                        if(this.$isDevelopment) console.warn('unitId', route);
                        return false;
                    }
                    if(store.getters?.unitsOnMap?.length) {
                        store.commit('setUnitsShowOnMap', {
                            showOnMap: false,
                            unitIds: store.getters?.unitsOnMap
                        })
                    }
                    let isLicenseActive = store.getters.getUnitLicenseById[unitId]?.isActive
                    if(!isLicenseActive) return false;

                    let routeParams = getUnitDefaultRouteParams(unitId)

                    router.push({
                        name: routeParams?.routeName+'.map',
                        params: {
                            ...routeParams?.query,
                        },
                        query: {...route.query}
                    })
                },
            },
        },
        components:{
            default: () => import('@/views/intuit/portal/UnitsPage.vue'),
        },
        children: [
            {
                path: ':unitId(\\d+)',
                name: consts.routerNames.units.lite,
                meta:{
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    weatherInMap: true,
                    // pageClass: 'full-size-window'
                },
            },
            {
                path: 'all',
                name: consts.routerNames.units.all,
                meta:{
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    weatherInMap: true,
                    pageClass: 'only-map'
                },
                children: [
                    {
                        path: ':unitId/info',
                        name: consts.routerNames.units.all + '.info',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.all,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                        }
                    },
                ]
            },
            {
                path: ':unitId(\\d+)/xvision/:date?/:type?/:trackXid?/:timeFrom?/:timeTo?/:trackColor?/:speed?',
                name: consts.routerNames.units.type.xvision,
                meta:{
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    pageClass: 'unit__xvision',
                    showMapInfo: false,
                    weatherInMap: true,
                    isVideo: true,
                },
                props: {
                    default: (route) => ({unitId: route.params.unitId})
                },
                components: {
                    default: () => import('@/components/units/info/UnitInfo_hasVideo.vue'),
                },
                children:[
                    {
                        path: 'lite',
                        name: consts.routerNames.units.type.xvision + '.lite',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xvision,
                            pageClass: 'unit__xvision unit-lite unit-lite__xvision',
                            weatherInMap: true
                        },
                    },
                    {
                        path: 'edit',
                        name: consts.routerNames.units.type.xvision + '.edit',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xvision,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: route.params.unitId,
                                time: Date.now(),
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                        }
                    },
                    {
                        path: 'info',
                        name: consts.routerNames.units.type.xvision + '.info',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xvision,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                        }
                    },
                    {
                        path: 'live-video',
                        name: consts.routerNames.units.type.xvision + '.live-video',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xvision,
                            pageClass: 'unit__xvision unit__xvision-video playback-hide',
                            showMapInfo: false
                        },
                        props: {
                            LiveVideoComponent: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            LiveVideoComponent: () => import('@/components/video/control/live-video/LiveVideo.vue'),
                        }
                    },
                    {
                        path: 'video-event/:eventXid',
                        name:  consts.routerNames.units.type.xvision + '.video-event',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xvision,
                            pageClass: 'unit__xvision unit__xvision-video',
                            showMapInfo: false,
                        },
                        props: {
                            VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                        },
                        components: {
                            VideoEventComponent: () => import('@/components/video/control/video-event/VideoEvent.vue')
                        },
                        children: [
                            {
                                path: 'lite',
                                name: consts.routerNames.units.type.xvision + '.video-event' + '.lite',
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision + '.lite',
                                    pageClass: 'unit__xvision unit-lite unit-lite__xvision unit-lite__xvision__video',
                                    weatherInMap: true
                                },
                            },
                        ]
                    },
                    {
                        path: 'snapshots/:eventXid',
                        name:  consts.routerNames.units.type.xvision + '.snapshots',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xvision,
                            pageClass: 'unit__xvision unit__xvision-video',
                            showMapInfo: false,
                        },
                        props: {
                            VideoEventComponent: (route) => ({unitId: route.params.unitId, eventXid: route.params.eventXid})
                        },
                        components: {
                            VideoEventComponent: () => import('@/components/units/info/UnitInfo_screenWall.vue'),
                        },
                        children: [
                            {
                                path: 'lite',
                                name: consts.routerNames.units.type.xvision + '.snapshots' + '.lite',
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision + '.lite',
                                    pageClass: 'unit__xvision unit-lite unit-lite__xvision unit-lite__xvision__video',
                                    weatherInMap: true
                                },
                            },
                        ]
                    },
                    {
                        path: 'video-request/:eventXid',
                        name: consts.routerNames.units.type.xvision + '.video-request',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xvision,
                            pageClass: 'unit__xvision unit__xvision-video playback-hide',
                            isVideo: true,
                            showMapInfo: false
                        },
                        props: {
                            VideoEventComponent: (route) => ({eventXid: route.params.eventXid})
                        },
                        components: {
                            VideoEventComponent: () => import('@/components/video/control/video-request/VideoRequest.vue')
                        },
                        children: [
                            {
                                path: 'lite',
                                name: consts.routerNames.units.type.xvision + '.video-request' + '.lite',
                                meta: {
                                    mainRouteName: consts.routerNames.units.main,
                                    parentRouteName: consts.routerNames.units.type.xvision + '.lite',
                                    pageClass: 'unit__xvision unit-lite unit-lite__xvision unit-lite__xvision__video',
                                    weatherInMap: true
                                },
                            },
                        ]
                    },
                    {
                        path: 'fast-geozone',
                        name: consts.routerNames.units.type.xvision + '.fastGeozone',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xvision,
                            sectionInfoByRoute: true,
                            fastGeozone: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: +route.params?.unitId,
                                time: Date.now()
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                        }
                    },
                ],
            },
            {
                path: ':unitId(\\d+)/xtrack/:date?/:type?/:trackXid?/:timeFrom?/:timeTo?/:trackColor?/:speed?',
                name: consts.routerNames.units.type.xtrack,
                meta:{
                    pageClass: 'unit__xtrack',
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    weatherInMap: true,
                },
                props: {
                    default: (route) => ({
                        unitId: route.params.unitId
                    })
                },
                components: {
                    default: () => import('@/components/units/info/UnitInfo_base.vue'),
                },
                children: [
                    {
                        path: 'lite',
                        name: consts.routerNames.units.type.xtrack + '.lite',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xtrack,
                            weatherInMap: true,
                            pageClass: 'unit__xtrack unit-lite unit-lite__xtrack',
                        },
                    },
                    {
                        path: 'edit',
                        name: consts.routerNames.units.type.xtrack + '.edit',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xtrack,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: route.params.unitId,
                                time: Date.now(),
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                        }
                    },
                    {
                        path: 'info',
                        name: consts.routerNames.units.type.xtrack + '.info',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xtrack,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                        }
                    },
                    {
                        path: 'fast-geozone',
                        name: consts.routerNames.units.type.xtrack + '.fastGeozone',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xtrack,
                            sectionInfoByRoute: true,
                            fastGeozone: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: +route.params?.unitId,
                                time: Date.now()
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                        }
                    },
                ],
            },
            {
                path: ':unitId(\\d+)/xpower/:date?/:type?/:trackXid?/:timeFrom?/:timeTo?/:trackColor?/:speed?',
                name: consts.routerNames.units.type.xpower,
                meta:{
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    pageClass: 'unit-generator-page unit-generator-main-page',
                    // weatherInMap: true,
                },
                props: {
                    default: (route) => ({
                        unitId: route.params.unitId,
                        sensors: ['last_message', 'phaseAB', 'phaseBC', 'phaseCA', 'fuel_level'],
                    }),
                },
                components: {
                    default: () => import('@/components/units/info/UnitInfo_generator.vue'),
                },
                children: [
                    {
                        path: 'lite',
                        name: consts.routerNames.units.type.xpower + '.lite',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xpower,
                            // pageClass: 'unit-lite unit-lite__xpower',
                        },
                    },
                    {
                        path: 'edit',
                        name: consts.routerNames.units.type.xpower + '.edit',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xpower,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: route.params.unitId,
                                time: Date.now(),
                            }),
                            main: (route) => ({
                                unitId: route.params.unitId,
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                            main: () => import('@/components/units/info/UnitInfo_generator_main.vue'),
                        }
                    },
                    {
                        path: 'info',
                        name: consts.routerNames.units.type.xpower + '.info',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xpower,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                        }
                    },
                    {
                        path: 'fast-geozone',
                        name: consts.routerNames.units.type.xpower + '.fastGeozone',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xpower,
                            sectionInfoByRoute: true,
                            fastGeozone: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: +route.params?.unitId,
                                time: Date.now()
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                        }
                    },
                    {
                        path: 'control',
                        name: consts.routerNames.units.type.xpower + '.control',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xpower,
                            pageClass: 'unit-generator-page unit-generator-main-page',
                        },
                        props: {
                            main: (route) => ({
                                unitId: route.params.unitId,
                            })
                        },
                        components: {
                            main: () => import('@/components/units/info/UnitInfo_generator_main.vue'),
                        },
                    },
                    {
                        path: 'analytics',
                        name: consts.routerNames.units.type.xpower + '.analytics',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xpower,
                            pageClass: 'unit-generator-page unit-generator-analytics-page',
                        },
                        props: {
                            main: (route) => ({
                                unitId: route.params.unitId,
                                grafanaSensors: ['phaseAB', 'phaseBC', 'phaseCA', 'fuel_lvl'],
                            })
                        },
                        components: {
                            main: () => import('@/components/units/info/UnitInfo_generator_analytics.vue'),
                        },
                    },
                    {
                        path: 'history',
                        name: consts.routerNames.units.type.xpower + '.history',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xpower,
                            pageClass: 'unit-generator-page unit-generator-history-page',
                        },
                        props: {
                            main: (route) => ({
                                unitId: route.params.unitId,
                            })
                        },
                        components: {
                            main: () => import('@/components/units/info/UnitInfo_generator_history.vue'),
                        },
                    },
                ],
            },
            {
                path: ':unitId(\\d+)/xcool/:date?/:type?/:trackXid?/:timeFrom?/:timeTo?/:trackColor?/:speed?',
                name: consts.routerNames.units.type.xcool,
                meta:{
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    componentsSettings: {
                        UnitInfo__hardware: {
                            class: 'hide-car-info'
                        },
                        UnitInfo__address: {
                            class: 'hide-car-info'
                        }
                    },
                    weatherInMap: true,
                },
                props: {
                    default: (route) => ({
                        unitId: route.params.unitId,
                    })
                },
                components: {
                    default: () => import('@/components/units/info/UnitInfo_reefer.vue'),
                },
                children: [
                    {
                        path: 'lite',
                        name: consts.routerNames.units.type.xcool + '.lite',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xcool,
                            pageClass: 'unit-lite unit-lite__xcool',
                        },
                    },
                    {
                        path: 'edit',
                        name: consts.routerNames.units.type.xcool + '.edit',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xcool,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: route.params.unitId,
                                time: Date.now(),
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                        }
                    },
                    {
                        path: 'info',
                        name: consts.routerNames.units.type.xcool + '.info',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xcool,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                        }
                    },
                    {
                        path: 'fast-geozone',
                        name: consts.routerNames.units.type.xcool + '.fastGeozone',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xcool,
                            sectionInfoByRoute: true,
                            fastGeozone: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: +route.params?.unitId,
                                time: Date.now()
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                        }
                    },
                    {
                        path: 'reefer',
                        name: consts.routerNames.units.type.xcool + '.reefer',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xcool,
                            pageClass: 'reefer-active map-bottom-right',
                        },
                        props: {
                            UnitControl_reefer: (route) => ({
                                unitId: +route.params?.unitId,
                            })
                        },
                        components: {
                            UnitControl_reefer: () => import('@/components/units/control/UnitControl_reefer.vue'),
                        }
                    },
                ],
            },
            {
                path: ':unitId(\\d+)/xgrafana/:date?/:type?/:trackXid?/:timeFrom?/:timeTo?/:trackColor?/:speed?',
                name: consts.routerNames.units.type.xgrafana,
                meta:{
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    pageClass: 'unit-graph map-bottom-right',
                    weatherInMap: false,
                    mapType: 'Google-Hybrid',
                    componentsSettings: {
                        UnitInfo__sensors: {
                            sensors: [
                                'last_message',
                                'speed',
                                'status',
                                'main_power',
                                'battery',
                                'inputs',
                                'outputs',
                            ],
                        }
                    },
                },
                props:{
                    default: (route) => ({
                        unitId: route.params.unitId,
                        //sensors: ['temperature', 'humidity', 'light', 'battery', 'shock'],
                    })
                },
                components: {
                    default: () => import('@/components/units/info/UnitInfo_aovx.vue'),
                },
                children: [
                    {
                        path: 'map',
                        name: consts.routerNames.units.type.xgrafana + '.map',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xgrafana,
                            pageClass: 'unit-graph unit-graph__xgrafana-map',
                            weatherInMap: true,
                        },
                    },
                    {
                        path: 'lite',
                        name: consts.routerNames.units.type.xgrafana + '.lite',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xgrafana,
                            pageClass: 'unit-lite unit-lite__xgrafana',
                            weatherInMap: true,
                        },
                    },
                    {
                        path: 'edit',
                        name: consts.routerNames.units.type.xgrafana + '.edit',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xgrafana,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: route.params.unitId,
                                time: Date.now(),
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                        }
                    },
                    {
                        path: 'info',
                        name: consts.routerNames.units.type.xgrafana + '.info',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xgrafana,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                        }
                    },
                    {
                        path: 'fast-geozone',
                        name: consts.routerNames.units.type.xgrafana + '.fastGeozone',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xgrafana,
                            sectionInfoByRoute: true,
                            fastGeozone: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: +route.params?.unitId,
                                time: Date.now()
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                        }
                    },
                ],
            },
            {
                path: ':unitId(\\d+)/xlink/:date?/:type?/:trackXid?/:timeFrom?/:timeTo?/:trackColor?/:speed?',
                name: consts.routerNames.units.type.xlink,
                meta:{
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    pageClass: 'section-unit-xlink',
                    weatherInMap: false,
                    showMapInfo: true,
                    componentsSettings: {
                        UnitInfo__sensors: {
                            class: 'sensors-xlink'
                        }
                    },
                },
                props:{
                    default: (route) => ({
                        unitId: route.params.unitId,
                        sensors: ['name', 'mac', 'connection_type', 'last_message', 'battery_voltage', 'temperature', 'humidity'],
                    })
                },
                components: {
                    default: () => import('@/components/units/info/UnitInfo_station.vue'),
                },
                children:[
                    {
                        path: 'lite',
                        name: consts.routerNames.units.type.xlink + '.lite',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xlink,
                            weatherInMap: true,
                            pageClass: 'section-unit-xlink unit-lite unit-lite__xlink',
                        },
                    },
                    {
                        path: 'edit',
                        name: consts.routerNames.units.type.xlink + '.edit',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xlink,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: route.params.unitId,
                                time: Date.now(),
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                        }
                    },
                    {
                        path: 'fast-geozone',
                        name: consts.routerNames.units.type.xlink + '.fastGeozone',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.xlink,
                            sectionInfoByRoute: true,
                            fastGeozone: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: +route.params?.unitId,
                                time: Date.now()
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                        }
                    },
                    // {
                    //     path: 'analytics',
                    //     name: consts.routerNames.units.type.xlink + '.analytics',
                    //     meta: {
                    //         mainRouteName: consts.routerNames.units.main,
                    //         parentRouteName: consts.routerNames.units.type.xlink,
                    //         pageClass: 'section-unit-links-lab section-unit-links-lab-analytics map-bottom-right',
                    //     },
                    //     props:{
                    //         analytics: (route) => ({
                    //             unitId: route.params.unitId
                    //         }),
                    //         sectionInfoByRoute: (route) => ({
                    //             unitId: route.params.unitId,
                    //         })
                    //     },
                    //     components: {
                    //         analytics: () => import('@/components/units/info/UnitInfo_station_analytics.vue'),
                    //         sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                    //     }
                    // }
                ]
            },
            {
                path: ':unitId(\\d+)/obd',
                name: consts.routerNames.units.type.obd,
                meta:{
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    pageClass: 'unit-obd',
                    showMapInfo: false,
                    weatherInMap: true,
                },
                props: {
                    default: (route) => ({unitId: route.params.unitId})
                },
                components: {
                    default: () => import('@/components/units/info/UnitInfo_OBD.vue'),
                },
                children:[
                    {
                        path: 'lite',
                        name: consts.routerNames.units.type.obd + '.lite',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.obd,
                            pageClass: 'unit-obd unit-lite unit-lite__obd',
                            weatherInMap: true
                        },
                    },
                    {
                        path: 'edit',
                        name: consts.routerNames.units.type.obd + '.edit',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.obd,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: route.params.unitId,
                                time: Date.now(),
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                        }
                    },
                    {
                        path: 'info',
                        name: consts.routerNames.units.type.obd + '.info',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.obd,
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                        }
                    },
                    {
                        path: 'fast-geozone',
                        name: consts.routerNames.units.type.obd + '.fastGeozone',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.obd,
                            sectionInfoByRoute: true,
                            fastGeozone: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: +route.params?.unitId,
                                time: Date.now()
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                        }
                    },
                ],
            },
            {
                path: ':unitId(\\d+)/obd-map',
                name: consts.routerNames.units.type.obd + '.map',
                meta:{
                    mainRouteName: consts.routerNames.units.main,
                    parentRouteName: consts.routerNames.units.main,
                    pageClass: 'unit-obd-map',
                    showMapInfo: false,
                    weatherInMap: true,
                },
                props: {
                    default: (route) => ({unitId: route.params.unitId})
                },
                components: {
                    default: () => import('@/components/units/info/UnitInfo_OBD.vue'),
                },
                children:[
                    {
                        path: 'lite',
                        name: consts.routerNames.units.type.obd + '.map.lite',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.obd + '.map',
                            pageClass: 'unit-obd unit-lite unit-lite__obd',
                            weatherInMap: true
                        },
                    },
                    {
                        path: 'edit',
                        name: consts.routerNames.units.type.obd + '.map.edit',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.obd + '.map',
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: route.params.unitId,
                                time: Date.now(),
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                        }
                    },
                    {
                        path: 'info',
                        name: consts.routerNames.units.type.obd + '.map.info',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.obd + '.map',
                            sectionInfoByRoute: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({unitId: route.params.unitId})
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/units/sectionInfo/UnitInfo.vue'),
                        }
                    },
                    {
                        path: 'fast-geozone',
                        name: consts.routerNames.units.type.obd + '.map.fastGeozone',
                        meta: {
                            mainRouteName: consts.routerNames.units.main,
                            parentRouteName: consts.routerNames.units.type.obd + '.map',
                            sectionInfoByRoute: true,
                            fastGeozone: true,
                        },
                        props: {
                            sectionInfoByRoute: (route) => ({
                                unitId: +route.params?.unitId,
                                time: Date.now()
                            })
                        },
                        components: {
                            sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/FastGeozoneEdit.vue'),
                        }
                    },
                ],
            },
        ],
        // beforeEnter: (to, from) => {
        //     if(from.name && from.name != 'Login'){// First load not correct getUserStorageKey == 0
        //         let unitsIdPage = {name: 'Units', params: {unitId: localStorage.getItem(store.getters.getUserStorageKey+'.unit.active')}}
        //         if(to.name == 'Units' && !to?.params?.unitId) {
        //             return to?.params?.unitId ? to : unitsIdPage
        //         } else{
        //             return true
        //         }
        //     } else
        //     if(to?.params?.unitId) {// First load
        //         return {...to, params: {}}
        //     }
        // },
        // Vuex version !!!
        // beforeEnter: (to) => {
        //     // let unitId = localStorage.getItem(this.getUserStorageKey + '.unit.active')*1
        //     let unitId = to.params?.unitId
        //     if (!unitId) {
        //         unitId = localStorage.getItem(store.getters.getUserStorageKey+'.unit.active')*1
        //         to.params.unitId = unitId
        //     }
        //     //return false
        // },
    },
];

export default routes